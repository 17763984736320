import { signIn, useSession } from "next-auth/react";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { PAGES } from "@/utils/pages";

export default function Signin() {
  const router = useRouter();
  const { status } = useSession();

  useEffect(() => {
    async function redirect() {
      if (status === "unauthenticated") {
        await signIn();
      } else if (status === "authenticated") {
        await router.push(PAGES.ORGANIZATIONS);
      }
    }
    redirect().catch(console.error);
  }, [status, router]);

  return <div></div>;
}
